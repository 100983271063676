<template>
  <div class="fo-booking-header-nav">
    <router-link :class="{'fillup': bookingStateTag > 0, 'active': bookingStateTag===0}" :to="`/sabre/${routerList[0]}`">
      <span v-if="bookingStateTag>0"><i class="fa-solid fa-check"></i></span>
      <span v-else>1</span>
      <label>{{$t("sabre.booking.passenger-details")}}</label>
    </router-link>

    <router-link :class="{'fillup': bookingStateTag > 1, 'active': bookingStateTag===1, 'disable': bookingStateTag<1}"
      :to="bookingStateTag<1 ? '': `/sabre/${routerList[1]}`" v-if="!isFCSupplied">
      <span v-if="bookingStateTag>1"><i class="fa-solid fa-check"></i></span>
      <span v-else>2</span>
      <label>{{$t("sabre.booking.extra-service")}}</label>
    </router-link>

    <router-link :class="{'fillup': bookingStateTag > 2, 'active': bookingStateTag===2, 'disable': bookingStateTag<2}"
      :to="bookingStateTag<2 ? '' : `/sabre/${routerList[2]}`">
      <span v-if="bookingStateTag>2"><i class="fa-solid fa-check"></i></span>
      <span v-else>{{ isFCSupplied ? '2' : '3'}}</span>
      <label>{{$t("sabre.booking.control-details")}}</label>
    </router-link>

    <router-link :class="{'active': bookingStateTag===3, 'disable': bookingStateTag<3}"
      :to="bookingStateTag<3 ? '' : `/sabre/${routerList[3]}`">
      <span>{{ isFCSupplied ? '3' : '4'}}</span>
      <label>{{$t("sabre.booking.payment")}}</label>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'fo-booking-navigation-header',
  computed: {
    ...mapGetters({
      bookingStateTag: 'GET_FO_BOOKING_STATE',
      routerList: 'GET_FO_BOOKING_ROUTERS',
      isFCSupplied: 'GET_FO_IS_FC_SUPPLIED',
    }),
  },

};
</script>

<style>
.fo-booking-header-nav .disable span{
  opacity: 0.5;
}
</style>
